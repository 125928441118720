(() => {
  const langBtn = document.getElementById('language-btn');
  const langMenu = document.getElementById('language-menu');
  const sidenavBtn = document.getElementById('sidenav-btn');
  const sidenavEl = document.getElementById('sidenav-el');
  
  const formBtn = document.getElementById('message-form-btn');
  formBtn.onclick = () => {
	  const formRender = document.getElementById('form-container');
	  formRender.innerHTML = `<form id="contactForm" class="form-send" action="/php/notify2.php">
          <input type="text" id="name" class="form-input" placeholder="ваше имя" name="name" required><br>
          <input type="text" id="phone" class="form-input" name="phone" placeholder="контакт (ваш email, whatsapp, телефон и т.д.)" required><br>
          <textarea id="message" name="message" rows="4" class="form-textarea" placeholder="ваше сообщение"
                    required></textarea><br>

          <button type="submit" class="btn btn-primary btn-lg">Отправить заявку</button>
		  <div class="preloader preloader-hidden" id="form-preloader">
			<div class="spinner"></div>
		  </div>
		  <div id="form-response" class="message message-hidden"></div>
        </form>`;
	  formSubmission();
  }

  sidenavBtn.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    sidenavEl.classList.toggle('d-none');
  });

  langBtn.addEventListener('click', (e) => {
    e.preventDefault();
    e.stopPropagation();
    langMenu.classList.toggle('d-none');
  });

  document.addEventListener('click', (e) => {
    window.setTimeout(() => {
      langMenu.classList.add('d-none');
      sidenavEl.classList.add('d-none');
    });
  });

  window.scrollToSendMessage = function () {
    const el = document.getElementById('send-message');
    el.scrollIntoView({behavior: "smooth"});
  };
  
  function formSubmission() {
	    // Handle form submission
	document.getElementById('contactForm').addEventListener('submit', function(event) {
    event.preventDefault(); // Prevent default form submission
    const form = this;
	const preloader = document.getElementById('form-preloader');
	const formResponse = document.getElementById('form-response');
	let response;
	
	const successMessage = 'Сообщение успешно отправлено';
	const errorMessage = 'Ошибка при отправке сообщения, обновите страницу и попробуйте еще раз';
	
	const body = new FormData(form);
	this.querySelectorAll('input, button, textarea').forEach(el => el.setAttribute('disabled', 'disabled'));
	preloader.classList.remove('preloader-hidden');
	
	fetch(form.action, { method: 'POST', body })
		.then(response => response.json())
		.then(data => {
			// Handle JSON response
			if (data.success) {
				formResponse.textContent = successMessage;
			} else {
				formResponse.textContent = errorMessage;
				formResponse.classList.add('message-error');
			}
			
			formResponse.classList.remove('message-hidden');
			preloader.classList.add('preloader-hidden');
		})
		.catch(error => {
			formResponse.textContent = errorMessage;
			formResponse.classList.add('message-error');
			formResponse.classList.remove('message-hidden');
			preloader.classList.add('preloader-hidden');
		});
	});
  }
})();
